<template>
	<div class="council">
		<SubHeader title="Award" subtitle="アワード" />

		<div class="container">
			<div class="row justify-content-center">
				<div class="col-lg-12">
					<ul class="nav nav-pills" id="myTab" role="tablist">
						<li class="nav-item">
							<a
								class="nav-link active"
								data-toggle="tab"
								href="#a"
								@click="
									group_id = 1;
									getAward();
								"
							>
								<span class="group">A</span>ディビジョン
							</a>
						</li>
						<li class="nav-item">
							<a
								class="nav-link"
								data-toggle="tab"
								href="#b"
								@click="
									group_id = 2;
									getAward();
								"
							>
								<span class="group">B</span>ディビジョン
							</a>
						</li>
						<li class="nav-item">
							<a
								class="nav-link"
								data-toggle="tab"
								href="#c"
								@click="
									group_id = 3;
									getAward();
								"
							>
								<span class="group">C</span>ディビジョン
							</a>
						</li>
						<li class="nav-item">
							<a
								class="nav-link"
								data-toggle="tab"
								href="#d"
								@click="
									group_id = 4;
									getAward();
								"
							>
								<span class="group">D</span>ディビジョン
							</a>
						</li>
						<li class="nav-item">
							<a
								class="nav-link"
								data-toggle="tab"
								href="#e"
								@click="
									group_id = 5;
									getAward();
								"
							>
								<span class="group">E</span>ディビジョン
							</a>
						</li>
						<!--<li class="nav-item">
							<a
								class="nav-link"
								data-toggle="tab"
								href="#f"
								@click="
									group_id = 6;
									getAward();
								"
							>
								<span class="group">F</span>ディビジョン
							</a>
						</li>-->
					</ul>

					<div class="stage_record mt-5">
						<h3>
							Stage Record
							<small>レコード</small>
						</h3>
						<div class="table-responsive">
							<table class="table table-striped">
								<thead class="thead-dark">
									<tr>
										<th scope="col" class="text-nowrap">Team Short Game</th>
										<th scope="col" class="text-nowrap">Doubles Short Game</th>
										<th scope="col" class="text-nowrap">Singles Short Game</th>
										<th scope="col" class="text-nowrap">High Off</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td class="text-nowrap">
											<template v-for="(teams, index) in record.team_short">
												<ul class="list-group" :key="'team_short' + index">
													<div class="team_name">
														{{ teams.members[0].team_name }}
													</div>
													<template v-for="(player, number) in teams.members">
														<li
															:key="'team_short_member' + index + '_' + number"
															class="list-group-item px-2 d-flex justify-content-between align-items-center"
														>
															{{ player.name }}
															<span
																class="badge badge-primary badge-pill"
																v-if="number == 0"
																>{{ Math.floor(teams.stats) }}</span
															>
														</li>
													</template>
												</ul>
											</template>
										</td>
										<td class="text-nowrap">
											<template v-for="(teams, index) in record.doubles_short">
												<ul class="list-group" :key="'doubles_short' + index">
													<div class="team_name">
														{{ teams.members[0].team_name }}
													</div>
													<template v-for="(player, number) in teams.members">
														<li
															:key="
																'doubles_short_member' + index + '_' + number
															"
															class="list-group-item px-2 d-flex justify-content-between align-items-center"
														>
															{{ player.name }}
															<span
																class="badge badge-primary badge-pill"
																v-if="number == 0"
																>{{ Math.floor(teams.stats) }}</span
															>
														</li>
													</template>
												</ul>
											</template>
										</td>
										<td class="text-nowrap">
											<template v-for="(teams, index) in record.singles_short">
												<ul class="list-group" :key="'singles_short' + index">
													<div class="team_name">
														{{ teams.members[0].team_name }}
													</div>
													<template v-for="(player, number) in teams.members">
														<li
															:key="
																'singles_short_member' + index + '_' + number
															"
															class="list-group-item px-2 d-flex justify-content-between align-items-center"
														>
															{{ player.name }}
															<span
																class="badge badge-primary badge-pill"
																v-if="number == 0"
																>{{ Math.floor(teams.stats) }}</span
															>
														</li>
													</template>
												</ul>
											</template>
										</td>
										<td class="text-nowrap">
											<template v-for="(teams, index) in record.high_off">
												<ul class="list-group" :key="'high_off' + index">
													<div class="team_name">
														{{ teams.members[0].team_name }}
													</div>
													<template v-for="(player, number) in teams.members">
														<li
															:key="'high_off_member' + index + '_' + number"
															class="list-group-item px-2 d-flex justify-content-between align-items-center"
														>
															{{ player.name }}
															<span
																class="badge badge-primary badge-pill"
																v-if="number == 0"
																>{{ Math.floor(teams.stats) }}</span
															>
														</li>
													</template>
												</ul>
											</template>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>

					<div class="stage_record mt-4 mb-5">
						<h3>
							Daily Record
							<small>日別レコード</small>
						</h3>
						<div class="table-responsive">
							<table class="table table-striped">
								<thead class="thead-dark">
									<tr>
										<th scope="col" class="text-nowrap">Date</th>
										<th scope="col" class="text-nowrap">Team Short Game</th>
										<th scope="col" class="text-nowrap">Doubles Short Game</th>
										<th scope="col" class="text-nowrap">Singles Short Game</th>
										<th scope="col" class="text-nowrap">High Off</th>
										<th scope="col" class="text-nowrap">180's</th>
									</tr>
								</thead>
								<tbody>
									<template v-for="(record, list_index) in list">
										<tr :key="'record' + list_index">
											<th scope="col" class="text-nowrap">{{ record.date }}</th>
											<td class="text-nowrap">
												<template
													v-for="(teams, index) in record.data.team_short"
												>
													<ul class="list-group" :key="'team_short' + index">
														<div class="team_name">
															{{ teams.members[0].team_name }}
														</div>
														<template v-for="(player, number) in teams.members">
															<li
																:key="
																	'team_short_member' + index + '_' + number
																"
																class="list-group-item px-2 d-flex justify-content-between align-items-center"
															>
																{{ player.name }}
																<span
																	class="badge badge-primary badge-pill"
																	v-if="number == 0"
																	>{{ Math.floor(teams.stats) }}</span
																>
															</li>
														</template>
													</ul>
												</template>
											</td>
											<td class="text-nowrap">
												<template
													v-for="(teams, index) in record.data.doubles_short"
												>
													<ul class="list-group" :key="'doubles_short' + index">
														<div class="team_name">
															{{ teams.members[0].team_name }}
														</div>
														<template v-for="(player, number) in teams.members">
															<li
																:key="
																	'doubles_short_member' + index + '_' + number
																"
																class="list-group-item px-2 d-flex justify-content-between align-items-center"
															>
																{{ player.name }}
																<span
																	class="badge badge-primary badge-pill"
																	v-if="number == 0"
																	>{{ Math.floor(teams.stats) }}</span
																>
															</li>
														</template>
													</ul>
												</template>
											</td>
											<td class="text-nowrap">
												<template
													v-for="(teams, index) in record.data.singles_short"
												>
													<ul class="list-group" :key="'singles_short' + index">
														<div class="team_name">
															{{ teams.members[0].team_name }}
														</div>
														<template v-for="(player, number) in teams.members">
															<li
																:key="
																	'singles_short_member' + index + '_' + number
																"
																class="list-group-item px-2 d-flex justify-content-between align-items-center"
															>
																{{ player.name }}
																<span
																	class="badge badge-primary badge-pill"
																	v-if="number == 0"
																	>{{ Math.floor(teams.stats) }}</span
																>
															</li>
														</template>
													</ul>
												</template>
											</td>
											<td class="text-nowrap">
												<template
													v-for="(teams, index) in record.data.high_off"
												>
													<ul class="list-group" :key="'high_off' + index">
														<div class="team_name">
															{{ teams.members[0].team_name }}
														</div>
														<template v-for="(player, number) in teams.members">
															<li
																:key="'high_off_member' + index + '_' + number"
																class="list-group-item px-2 d-flex justify-content-between align-items-center"
															>
																{{ player.name }}
																<span
																	class="badge badge-primary badge-pill"
																	v-if="number == 0"
																	>{{ Math.floor(teams.stats) }}</span
																>
															</li>
														</template>
													</ul>
												</template>
											</td>
											<td class="text-nowrap">
												<template v-for="(teams, index) in record.data.ton80">
													<ul class="list-group" :key="'ton80' + index">
														<div class="team_name">
															{{ teams.members[0].team_name }}
														</div>
														<template v-for="(player, number) in teams.members">
															<li
																:key="'ton80_member' + index + '_' + number"
																class="list-group-item px-2 d-flex justify-content-between align-items-center"
															>
																{{ player.name }}
															</li>
														</template>
													</ul>
												</template>
											</td>
										</tr>
									</template>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import Vue from "vue";
import SubHeader from "@/components/common/SubHeader.vue";
//import Schedule from "@/components/common/Schedule.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
Vue.use(Loading);

export default {
	name: "schedule",
	components: {
		SubHeader,
	},
	data() {
		return {
			isLoading: true,
			record: [],
			list: [],
			group_id: null,
		};
	},
	mounted: function () {
		this.getAward();
	},
	methods: {
		getAward: function () {
			this.isLoading = true;
			let loader = this.$loading.show({
				// Optional parameters
				container: this.fullPage ? null : this.$refs.formContainer,
				canCancel: false,
				color: this.$store.state.primary_color,
				backgroundColor: "#666",
			});
			let params = {
				group_id: this.group_id,
			};
			this.axios
				.get("https://admin.darts-l.com/api/odo/award", {
					params: params,
				})
				.then((res) => {
					//console.log("res:" + JSON.stringify(res.data.list));
					this.record = res.data.record;
					this.list = res.data.list;
					// simulate AJAX
					setTimeout(() => {
						loader.hide();
						this.isLoading = false;
					}, 500);
				});
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container {
	padding: 4rem 1rem 0 1rem;
	text-align: left;
}
.table-bordered {
	border: 1px solid #999;
	td,
	th {
		border: 1px solid #999;
		vertical-align: middle;
	}
}
.team_name {
	color: #999;
}
.list-group-item {
	border: none;
	background-color: transparent;
	padding: 0 1.25rem;
	.badge {
		font-size: 1.2rem;
	}
}
</style>
